import React from 'react'
import Layout from '../components/layouts/Layout'
import { InfoPagesWrapper } from '../components/layouts'
import { Button, ExtLinks } from "../components/elements"
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import SEO from "../components/seo"

const SoftwareDevBlog = () => {
  const data = useStaticQuery(graphql`
      query {
          file(relativePath: { regex: "/software-dev-blog.jpeg/" }) {
              childImageSharp {
                  fluid(maxWidth: 2000) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `)
  return (
    <Layout>
      <SEO title="Example 6, Software Dev Blog" />

      <InfoPagesWrapper>
        <h1>Software Development Blog</h1>

        <p>
          {`Here is a well functioning blog with a modern, fast web framework. It has a nice animation for the main logo when loading the page, and does an excellent job at presenting the blog to viewers, with full code syntax highlighting and nice styling for the articles. It is
          extremely lightweight, fast to load, and is presented well`}
        </p>
        <ExtLinks href="https://tomcaraher.dev/" target="_blank" rel="noreferrer">
          Take Me There!
        </ExtLinks>
      </InfoPagesWrapper>
      <div
        css={`
          width: 80%;
          margin: 2rem auto;
          img {
            object-fit: fill !important;
          }
        `}
      >
        <Img fluid={data.file.childImageSharp.fluid} />
      </div>
    </Layout>
  )
}

export default SoftwareDevBlog
